<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

        

        <a-row :gutter="24" type="flex">

            <vue-loaders-ball-beat v-if="loading" color="red" scale="1"></vue-loaders-ball-beat>

            <a-col :span="20" class="mb-24">
                <!-- Header Search Input -->
					<a-input-search class="header-search" :class="searchLoading ? 'loading' : ''" placeholder="Type here…" @search="onSearch" :loading='searchLoading'>
						<svg slot="prefix" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z" fill="#111827"/>
						</svg>
					</a-input-search>
            </a-col>

            <a-col :span="4" class="mb-24">
                <a-button type="primary" block style="margin-left: 9px;">
                    Add a Connector
                </a-button>
            </a-col>
            
        </a-row>

        

		<!-- Authors Table -->
		<a-row :gutter="24">

            <p v-if="project_connectors.length<1">No items to display, check that you have data sources configured.</p>

			<!-- Authors Table Column -->
            <a-col :span="5"  v-for="item in project_connectors" v-bind:key="item.id" style="margin:7px;">

				
				<WidgetConnector 
                :title="item.name"
                :connector_type="item.connector_type"
                :connector_id="item.id"           
                >
            
            </WidgetConnector>

			</a-col>
			<!-- / Authors Table Column -->

		</a-row>
		<!-- / Authors Table -->
	</div>
</template>

<script>

	// "Authors" table component.
	import CardProjectDisplay from '../components/Cards/CardProjectDisplay' ;

 	// "Projects" table component.
	import WidgetConnector from '../components/Widgets/WidgetConnector' ;

    import {fetchDataJSON, fetchPostJSON} from '../services/v1/services';
	

	export default ({
		components: {
			CardProjectDisplay,
			WidgetConnector,
		},
        beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
        async mounted() {

            const prefrenceRes = await fetchDataJSON('users/profiles/preferences/show', true);

            this.preferences = prefrenceRes.data.preferences;

            if(this.preferences.current_project_id != null){
				this.current_project_id = this.preferences.current_project_id;
			}

            if(this.current_project_id == null) {
                this.error = 'Nothing to display. Please select a project first.';
            }


            const projectConnectorsRes = await fetchDataJSON('projects/' + this.current_project_id + '/connectors/list' , true);

            this.project_connectors = projectConnectorsRes.data.project_connectors;

            this.$eventHub.$on("currentProjectUpdated", async (item) => {
                console.log('currentProjectUpdated indir', item);

                this.current_project_id = item.value;

                const projectConnectorsRes = await fetchDataJSON('projects/' + this.current_project_id + '/connectors/list' , true);

                this.project_connectors = projectConnectorsRes.data.project_connectors;
            });

            this.loading = false;
        },
        methods: {
            initCreateProjectChannelModal() {
                this.showNested = true;
                this.$refs.createprojectchannelmodal.open();
            },
            closeNested() {
                this.showNested = false;
            },
            async handleSubmitProjectChannel(e) {
                e.preventDefault();
                
                // validate form

                this.form.validateFields(async(err, values) => {
                    if ( !err ) {
                        console.log('Received values of form: ', values) ;

                        this.loading = true;

                        this.error = null;

                        const item = {
                            name: values.name,
                            description: values.description,
                            channel_type: this.selectedChannelType,
                            channel_provider: this.selectedChannelProvider,
                            project_id: this.current_project_id,
                        };

                        const res = await fetchPostJSON('projects/channels/create',item, true);

                        console.log(res);

                        this.$refs.createprojectchannelmodal.close();


                        const projectChannelsRes = await fetchDataJSON('projects/' + this.current_project_id + '/channels/list' , true);

                        this.project_connectors = projectChannelsRes.data.project_connectors;


                        this.loading = false;

                        this.newProjectName = '';
                        this.newProjectDescription = '';
                        this.selectedChannelType = 'voice';
                        this.selectedChannelProvider = 'twilio';
                    }
                });

                // await this.addProject();
            },
            async onSearch(value) {
                this.searchLoading = true;

                console.log('searching', value);

                const projectConnectorsRes = await fetchDataJSON('projects/' + this.current_project_id + '/connectors/list?term=' + value , true);

                this.project_connectors = projectConnectorsRes.data.project_connectors;

                this.searchLoading = false;
            },

            async addProject() {

                this.loading = true;

                this.error = null;
                
                const projectCreateRes = await fetchPostJSON('projects/create', true, {
                    name: this.newProjectName,
                    description: this.newProjectDescription,
                    type: this.selectedChannelType,
                });

                // this.projects.push(projectRes.data.project);

                const projectsRes = await fetchDataJSON('projects/list', true, {
                    search: value,
                });

                this.projects = projectsRes.data.projects;

                this.loading = false;

                this.newProjectName = '';
                this.newProjectDescription = '';
                this.selectedChannelType = 'full';
            },
        },
		data() {
			return {

                project_connectors: [],

                loading: true,

                error: null,

                searchLoading: false,

                showNested: false,

                channelTypeOptions: [
                    {
                        value: 'voice',
                        label: 'Voice Channel',
                    },
                    {
                        value: 'whatsapp',
                        label: 'WhatsAPP Channel',
                    }
                ],

                channelProviderOptions: [
                    {
                        value: 'twilio',
                        label: 'Twilio',
                    },
                    {
                        value: 'whapi',
                        label: 'Whapi',
                    },
                    {
                        'value': 'semahub',
                        'label': 'Semahub',
                    }
                ],

                selectedChannelType: 'voice',

                selectedChannelProvider: 'twilio',

                newProjectName: '',

                newProjectDescription: '',

                projectColumns: [
                    {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name',
                        scopedSlots: { customRender: 'name' },
                    },
                    {
                        title: 'Description',
                        dataIndex: 'description',
                        key: 'description',
                        scopedSlots: { customRender: 'description' },
                    },
                    {
                        title: 'Status',
                        dataIndex: 'status',
                        key: 'status',
                        scopedSlots: { customRender: 'status' },
                    },
                    
                ],
			}
		},
	})

</script>

<style lang="scss">

.selector-thick {
    width: 100%;

    height: 40px
}
</style>