<template>
    <!-- Salary Card -->
    <a-card :bordered="false" class="widget-2 h-full" style="margin-bottom: 13px; box-shadow: inset;">

        <template #title>

            <i :class="iconClass" aria-hidden="true"></i>

        </template>

        <div style="margin: 13px;">

            <a-descriptions :bordered="false" size="small" :column="1">

                <a-descriptions-item label="Title">{{ title }}</a-descriptions-item>
                <a-descriptions-item label="Type">{{ connector_type }}</a-descriptions-item>
            

            </a-descriptions>

            <a-button style="width: 100%;" type="primary" @click="viewProject">View</a-button>


        </div>



    </a-card>
    <!-- / Salary Card -->
</template>

<script>

export default ({
    props: {
        title: {
            type: String,
            default: "",
        },
        connector_type: {
            type: String,
            default: "",
        },
        connector_id: {
            type: String,
            default: "",
        },
    },
    computed: {
        iconClass() {
            if (this.connector_type == "database") {
                return "fa fa-database icon";
            } else if (this.connector_type == "analytics") {
                return "fa fa-table icon";

            }
            else {
                return "fa fa-table icon";
            }
        }
    },
    methods: {
        viewProject() {
            console.log("view project");
            this.$router.push("/connectors/" + this.connector_id);
        }
    },
    data() {
        return {
        }
    },
})

</script>